/**
 * First page visit checker (https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/type)
 *
 * @return {boolean}
 */
export default () => {
  const oldChecker = window.performance.navigation && window.performance.navigation.type === 0;
  const actualChecker = window.performance
    .getEntriesByType('navigation')
    .map((nav) => nav.type)
    .includes('navigate');
  // for iOS and macOS Safari check
  sessionStorage.setItem('firstVisitChecked', 'true');
  return oldChecker || actualChecker;
};
