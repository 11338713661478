/* eslint-disable no-shadow */

export default function ({
  state = {},
  mutations = {},
  ...other
}) {
  /**
   * Default state.
   *
   * @return {Object}
   */
  function getDefaults() {
    if (typeof state === 'function') {
      return state();
    }

    return {
      ...state,
    };
  }

  return {

    state: getDefaults(),

    mutations: {
      set(state, value) {
        Object.entries(value).forEach(([key, data]) => {
          if (!Array.isArray(state[key]) && state[key] && typeof state[key] === 'object') {
            state[key] = {
              ...state[key],
              ...data,
            };
          } else {
            state[key] = data;
          }
        });
      },
      reset(state) {
        Object.assign(state, getDefaults());
      },
      ...mutations,
    },
    ...other,
  };
}
