/**
 * Build full user name.
 *
 * @param  {string} lastName
 * @param  {string} firstName
 * @return {string}
 */
export default ({ lastName, firstName }) => {
  return [
    firstName,
    lastName,
  ].filter((v) => v).join(' ');
};
