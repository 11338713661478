export const API_ADMIN_BASEURL = process.env.VUE_APP_API_ADMIN_URL;
export const API_ADMIN_TIMEOUT = process.env.VUE_APP_API_TIMEOUT;

export const API_PAY_BASEURL = process.env.VUE_APP_API_URL;
export const API_PAY_TIMEOUT = process.env.VUE_APP_API_TIMEOUT;

function createSwitchMap(baseURL) {
  return [
    {
      name: 'eu',
      baseURL,
      country: ['default', 'gb', 'de', 'ch', 'us'],
    },
    {
      name: 'uae',
      baseURL: baseURL.replace(/^(https?:\/\/)uk([.-])/, '$1uae$2'),
      country: ['ae'],
    },
  ];
}

export const API_SWITCH_VAR = '_easytip-server';
export const API_SWITCH_ENABLED = process.env.VUE_APP_API_SWITCH === 'true';
export const API_SWITCH_ADMIN = createSwitchMap(API_ADMIN_BASEURL);
export const API_SWITCH_PAY = createSwitchMap(API_PAY_BASEURL);
