<template>
  <ui-modal
    v-bind="$attrs"
    v-on="$listeners"
    class="modal-submit-pay"
  >
    <template #default="{ close }">
      <div class="modal-submit-pay__content">
        <div class="modal-submit-pay__title">
          {{ $t('dialogue.submitPay.title') }}
        </div>
        <div class="modal-submit-pay__text">
          {{ $t('dialogue.submitPay.text', { total }) }}
        </div>
        <ui-button class="modal-submit-pay__btn modal-submit-pay__submit" @click="$emit('submit')">
          {{ $t('dialogue.submitPay.submit', { total }) }}
        </ui-button>
        <ui-button class="modal-submit-pay__btn modal-submit-pay__cancel" ghost @click="close">
          {{ $t('dialogue.submitPay.cancel') }}
        </ui-button>
      </div>
    </template>
  </ui-modal>
</template>

<script>
import UiButton from './UiButton.vue';
import UiModal from './UiModal.vue';

export default {

  inheritAttrs: false,

  components: {
    UiButton,
    UiModal,
  },

  props: {
    total: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .modal-submit-pay {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 18px;
    }
    &__title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      margin-top: 24px;
      text-align: center;
    }
    &__btn {
      border-radius: 42px;
      padding: 5px 27px;
      width: fit-content;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      height: 36px;
    }
    &__submit {
      margin-top: 24px;
    }
    &__cancel {
      color: #A01212;
      margin-top: 18px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
</style>
