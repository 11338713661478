<template>
  <ui-modal
    v-bind="$attrs"
    title="Cookie Policy"
    class="modal-policy"
    v-on="$listeners"
  >
    <template v-if="$scopedSlots.activator" #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <template #default="{ close }">
      <div class="modal-policy__text">
        <p>{{ $t('cookiePolicy.weAreKeenly') }}</p>

        <h2 id="section-1">{{ $t('cookiePolicy.introduction.title') }}</h2>
        <p v-html="$t('cookiePolicy.introduction.text[0]')"></p>
        <p>{{ $t('cookiePolicy.introduction.text[1]') }}</p>
        <p>{{ $t('cookiePolicy.introduction.text[2]') }}</p>

        <h2 id="section-2">{{ $t('cookiePolicy.useOfCookies.title') }}</h2>
        <p>{{ $t('cookiePolicy.useOfCookies.text[0]') }}</p>
        <p v-html="$t('cookiePolicy.useOfCookies.text[1]')"></p>

        <h2 id="section-3">{{ $t('cookiePolicy.typesOfCookies.title') }}</h2>
        <p>{{ $t('cookiePolicy.typesOfCookies.text[0]') }}</p>
        <p>{{ $t('cookiePolicy.typesOfCookies.text[1]') }}</p>
        <p>{{ $t('cookiePolicy.typesOfCookies.text[2]') }}</p>
        <p>{{ $t('cookiePolicy.typesOfCookies.text[3]') }}</p>
        <p>{{ $t('cookiePolicy.typesOfCookies.text[4]') }}</p>
        <p>{{ $t('cookiePolicy.typesOfCookies.text[5]') }}</p>
        <p>{{ $t('cookiePolicy.typesOfCookies.text[6]') }}</p>
        <p>{{ $t('cookiePolicy.typesOfCookies.text[7]') }}</p>
        <p>{{ $t('cookiePolicy.typesOfCookies.text[8]') }}</p>
        <p>{{ $t('cookiePolicy.typesOfCookies.text[9]') }}</p>
        <p>{{ $t('cookiePolicy.typesOfCookies.text[10]') }}</p>

        <h2>{{ $t('cookiePolicy.CookiesUsedByQrTip.title') }}</h2>
        <ui-data-table :columns="columns" :rows="rows" />
        <h2 id="section-4">{{ $t('cookiePolicy.ControlOfCookies.title') }}</h2>
        <p>{{ $t('cookiePolicy.ControlOfCookies.text[0]') }}</p>
        <p>{{ $t('cookiePolicy.ControlOfCookies.text[1]') }}</p>
        <ul>
          <li><a href="https://support.apple.com/kb/ph19214?locale=en_US">Apple Safari</a></li>
          <li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">Google Chrome</a></li>
          <li><a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">Microsoft Edge</a></li>
          <li><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Microsoft Internet Explorer</a></li>
          <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla Firefox</a></li>
          <li><a href="www.opera.com/help/tutorials/security/cookies/">Opera</a></li>
          <li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1">Android (Chrome) </a></li>
          <li><a href="https://help.blackberry.com/en/blackberry-classic/10.3.1/help/mwa1334238823957.html">Blackberry</a></li>
          <li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&hl=en&oco=1">Iphone or Ipad (Chrome)</a></li>
          <li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1">Iphone or Ipad (Safari)</a></li>
        </ul>
        <p v-html="$t('cookiePolicy.ControlOfCookies.text[2]')"></p>
        <h2 id="section-5">{{ $t('cookiePolicy.OtherTrackingTechnologies.title') }}</h2>
        <p>{{ $t('cookiePolicy.OtherTrackingTechnologies.text[0]') }}</p>
        <h2 id="section-6">{{ $t('cookiePolicy.privacyPolicy.title') }}</h2>
        <p v-html="$t('cookiePolicy.privacyPolicy.text[0]')"></p>
        <h2 id="section-7">{{ $t('cookiePolicy.contactUs.title') }}</h2>
        <p>{{ $t('cookiePolicy.contactUs.text[0]') }}</p>
        <p v-html="$t('cookiePolicy.contactUs.text[1]')"></p>
      </div>
      <ui-button @click="close">
        {{ $t('cookiePolicy.button') }}
      </ui-button>
    </template>
  </ui-modal>
</template>

<script>
import UiModal from './UiModal.vue';
import UiButton from './UiButton.vue';
import UiDataTable from './UiDataTable.vue';

export default {
  inheritAttrs: false,
  components: {
    UiModal,
    UiButton,
    UiDataTable,
  },
  data() {
    return {
      columns: [
        { label: this.$t('cookiePolicy.CookiesUsedByQrTip.name'), width: 100 },
        { label: this.$t('cookiePolicy.CookiesUsedByQrTip.purpose'), width: 200 },
        { label: this.$t('cookiePolicy.CookiesUsedByQrTip.type'), width: 100 },
      ],
      rows: [
        { name: 'recent-views', purpose: 'stripe.com', type: 'string' },
        { name: '_ga', purpose: 'stripe.com', type: 'string' },
        { name: '__utma', purpose: 'stripe.com', type: 'string' },
        { name: '__utmz', purpose: 'stripe.com', type: 'string' },
        { name: 'docs.prefs', purpose: 'stripe.com', type: 'string' },
        { name: '__stripe_mid', purpose: 'stripe.com', type: 'string' },
        { name: '__stripe_orig_props', purpose: 'stripe.com', type: 'string' },
        { name: 'lang', purpose: 'stripe.com', type: 'string' },
        { name: '__ca__chat', purpose: 'easytip.net', type: 'string' },
        { name: 'amp_89a0cd', purpose: 'easytip.net', type: 'string' },
      ],
    };
  },
};
</script>

<style lang="scss">
.modal-policy {
  color: #3C4858;

  &__text {
    font-weight: 300;
    line-height: 1.5em;

    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.5em;
    }

    p {
      font-size: 14px;
      margin: 0 0 10px;
    }
  }
}
</style>
