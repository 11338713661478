/* eslint-disable class-methods-use-this, max-classes-per-file */

import {
  API_SWITCH_VAR,
  API_SWITCH_ENABLED,
  API_SWITCH_ADMIN,
  API_SWITCH_PAY,
} from '@/config/api';
import api from '@/api/instance';
import AxiosInterceptor from '@/class/ApiSwitch/AxiosInterceptor';
import storage from './storage';

class AdminSwitch extends AxiosInterceptor {
  /**
   * Constructor.
   *
   * @param {string} selected
   * @param {Function} onUpdate
   */
  constructor(selected, onUpdate) {
    super({
      axios: api.admin,
      items: API_SWITCH_ADMIN,
      selected,
      onUpdate,
    });
  }

  /** @inheritdoc */
  isAuthRequest(config) {
    return config.method === 'get' && this.isUrl(config, /^\/v1\/public\/workplaces\/(\d+)$/);
  }

  /** @inheritdoc */
  isAuthError({ status, data }) {
    return status === 404;
  }
}

class PaySwitch extends AxiosInterceptor {
  constructor(selected) {
    super({
      axios: api.pay,
      items: API_SWITCH_PAY,
      selected,
    });
  }
}

export default (Vue) => {
  if (!API_SWITCH_ENABLED) {
    return;
  }

  const selected = storage.get(API_SWITCH_VAR);

  const pay = new PaySwitch(selected);
  const admin = new AdminSwitch(selected, (value) => {
    storage.set(API_SWITCH_VAR, value);
    pay.selected = value;
  });

  pay.boot();
  admin.boot();

  Vue.prototype.$apiSwitch = admin;
};
