<template>
  <table>
    <thead>
      <tr>
        <th
          v-for="column in columns"
          :key="column.label"
          :width="column.width"
        >
          {{ column.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="row in rows"
        :key="row.id"
      >
        <td>{{ row.name }}</td>
        <td>{{ row.purpose }}</td>
        <td>{{ row.type }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    rows: Array,
    columns: Array,
  },
};
</script>

<style lang="scss">
table {
  width: 100%;
  margin: 40px 0;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #3C4858;
  text-align: left;
  padding: 8px;
  color: #000;
}

th {
  background-color: #6999D1;
}
</style>
