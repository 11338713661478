<template>
  <span
    class="ui-money"
    :class="{
      'ui-money_long': money.currencyLong,
      'ui-money_rtl': money.currencyRtl,
      'ui-money_no-style': noStyle,
    }"
    :data-currency="money.currencyIso"
  >
    <template v-if="money.currencyRtl">
      <span class="ui-money__amount">
        {{ decimal }}
      </span>
      <span class="ui-money__currency">
        {{ money.currencyLabel }}
      </span>
    </template>
    <template v-else>
      <span class="ui-money__currency">
        {{ money.currencyLabel }}
      </span>
      <span class="ui-money__amount">
        {{ decimal }}
      </span>
    </template>
  </span>
</template>

<script>
import Money from '@/class/Money';

export default {
  name: 'UiMoney',

  props: {
    amount: {
      type: [Number, String],
      required: false,
    },
    currency: {
      type: Object,
      required: false,
    },
    noStyle: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Object,
      required: false,
    },
    options: {
      type: Object,
      default: () => ({
        fractionDigits: {
          type: Number,
          default: 2,
        },
      }),
    },
  },

  computed: {
    money() {
      return this.value || new Money(this.amount, this.currency);
    },
    decimal() {
      return this.money.toDecimal();
    },
  },
};
</script>

<style lang="scss">
.ui-money {
  display: inline-flex;
  align-items: baseline;
  white-space: nowrap;

  &_long &__currency {
    font-size: 0.5em;
    margin-right: 2px;
  }

  &_no-style &__currency {
    font-size: inherit;
  }

  &_long#{&}_rtl &__currency {
    margin-left: 2px;
    margin-right: 0;
  }
}
</style>
