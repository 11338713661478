import axios from 'axios';
import ApiInterceptor from './ApiInterceptor';

export default class AxiosInterceptor extends ApiInterceptor {
  /**
   * @type {AxiosInstance}
   */
  axios = null;

  /**
   * Constructor.
   *
   * @param {AxiosInstance} parameters.axios
   * @param {Object} parameters
   */
  constructor({ axios: axiosInstance, ...parameters }) {
    super(parameters);

    this.axios = axiosInstance;
  }

  /**
   * Attach interceptors to axios instance.
   */
  boot() {
    this.axios.interceptors.request.use((config) => {
      return this.onRequest(config);
    });

    this.axios.interceptors.response.use(
      (response) => {
        this.onSuccess(response.config);

        return response;
      },
      (error) => {
        if (axios.isCancel(error)) {
          return false;
        }

        const { response, config } = error;
        const nextConfig = this.onError(response, config);

        if (nextConfig) {
          return this.axios(nextConfig);
        }

        throw error;
      },
    );
  }
}
