/**
 * @see {@link https://gist.github.com/ashour/49e2f04a5664b18b26f43c413a27ee08}
 * @param  {Object} options
 * @return {string}
 */
export default function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: true };

  const opt = { ...defaultOptions, ...options };

  const navigatorLocale = navigator.languages !== undefined
    ? navigator.languages[0]
    : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();

  return trimmedLocale;
}
