<template>
  <button
    class="ui-icon-button"
    :class="{
      'ui-icon-button_disabled': disabled,
    }"
    :disabled="disabled"
    v-on="$listeners"
  >
    <slot>
      <ui-icon
        v-bind="$attrs"
      />
    </slot>
  </button>
</template>

<script>
import UiIcon from './UiIcon.vue';

export default {

  inheritAttrs: false,

  components: {
    UiIcon,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.ui-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
  padding: 2px;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &_disabled {
    cursor: default;
  }
}
</style>
