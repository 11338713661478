<template>
  <div class="cookie-banner" :class="{ 'cookie-banner_closed': isAccepted }">
    <div class="cookie-banner__wrapper">
      <p class="cookie-banner__content">
        {{ $t('cookieBanner.text') }}
        <modal-policy class="cookie-banner__modal">
          <template #activator="{ open }">
            <a
              class="cookie-banner__link"
              href="javascript:void(0)"
              @click.self.stop.prevent="open"
            >
              {{ $t('cookieBanner.link') }}
            </a>
          </template>
        </modal-policy>
      </p>
      <ui-button
        outlined
        transparent
        @click="accept"
      >
        {{ $t('cookieBanner.button') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UiButton from './UiButton.vue';
import ModalPolicy from './ModalPolicy.vue';

export default {
  components: {
    UiButton,
    ModalPolicy,
  },
  computed: {
    ...mapState('cookiePolicy', [
      'isAccepted',
    ]),
  },
  methods: {
    ...mapActions('cookiePolicy', [
      'loadCookie',
      'acceptCookie',
    ]),
    accept() {
      this.acceptCookie();
    },
  },
  created() {
    this.loadCookie();
  },
};
</script>

<style lang="scss">
.cookie-banner {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;

  &__wrapper {
    display: flex;
    align-items: center;
    height: 77px;
    padding: 0 20px;
  }

  &__content {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 130%;
    max-width: 242px;
    color: #fff;
    margin-right: 9px;
  }

  &_closed {
    display: none !important;
  }

  &__link {
    color: #fff;
  }

  &__modal {
    display: inline-block;
  }
}
</style>
