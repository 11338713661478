/* eslint-disable class-methods-use-this */

/**
 * @alias Storage
 */
class Storage {
  /**
   * Storage driver.
   *
   * @param {Object} state localStorage or sessionStorage implementation.
   */
  constructor(state) {
    /**
     * State in private property.
     *
     * @memberof Storage/Storage#
     * @name driver
     * @type {Object}
     */
    Object.defineProperty(this, 'state', {
      value: state,
      enumerable: false,
      configurable: false,
      writable: false,
    });
  }

  /**
   * Save value by name.
   *
   * @param {string} name
   * @param {*} value
   */
  set(name, value) {
    try {
      this.state.setItem(name, this.encode(value));
    } catch (e) {
      // noop
    }
  }

  /**
   * Get value by name.
   *
   * @param  {string} name
   * @return {*}
   */
  get(name) {
    try {
      const value = this.state.getItem(name);

      return this.decode(value);
    } catch (e) {
      // noop
    }

    return null;
  }

  /**
   * Serialize value.
   *
   * @param  {*} value
   * @return {*}
   */
  encode(value) {
    return JSON.stringify(value);
  }

  /**
   * Unserialize value.
   *
   * @param  {*} value
   * @return {*}
   */
  decode(value) {
    if (typeof value === 'string') {
      try {
        value = JSON.parse(value);

        return value;
      } catch (e) {
        // noop
      }
    }

    return value;
  }
}

export default Storage;
