<template>
  <div class="ui-spinner" :style="{ width: size, height: size }">
    <svg viewBox="12.5 12.5 25 25" :width="size" :height="size">
      <circle
        cx="25"
        cy="25"
        r="10"
        fill="none"
        stroke-width="4"
        stroke-miterlimit="20"
      />
    </svg>
  </div>
</template>

<script>
export default {

  props: {
    size: {
      type: [Number, String],
      default: '22px',
    },
  },
};
</script>

<style lang="scss">
.ui-spinner {
  position: absolute;

  svg {
    animation: rotate 2s linear infinite;
    position: relative;
  }

  circle {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: currentColor;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1 100;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 45 100;
    stroke-dashoffset: -18;
  }

  100% {
    stroke-dasharray: 45 100;
    stroke-dashoffset: -62;
  }
}
</style>
