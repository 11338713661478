// 5 minutes
const relevanceTime = 5 * 60 * 1000;

const getTransactionHash = ({
  locationPayoutId = '0',
  amount = '0',
  staffId = null,
} = {}) => {
  return `${locationPayoutId}-${staffId || locationPayoutId}-${amount}`;
};

export const setTransactionHash = ({ locationPayoutId, amount, staffId }) => {
  const lastTransactionHash = getTransactionHash({
    locationPayoutId,
    amount,
    staffId,
  });
  localStorage.setItem('lastTransactionHash', lastTransactionHash);
  const notActualAfter = (+new Date() + relevanceTime).toString();
  localStorage.setItem('lastTransactionHashRelevanceDeadline', notActualAfter);
};

export const checkTransactionCollision = ({ locationPayoutId, amount, staffId }) => {
  const deadlineOfLastHash = localStorage.getItem('lastTransactionHashRelevanceDeadline') || 0;
  if (+deadlineOfLastHash < +new Date()) {
    return false;
  }

  const hashOfCurrentTransaction = getTransactionHash({ locationPayoutId, amount, staffId });
  const hashOfLastTransaction = localStorage.getItem('lastTransactionHash');

  return hashOfCurrentTransaction === hashOfLastTransaction;
};

export default {
  setTransactionHash,
  checkTransactionCollision,
};
