import { times, random } from 'lodash';

/**
 * Generate hex string.
 *
 * @param  {number} size
 * @return {string}
 */
function hex(size) {
  return times(size, () => random(15).toString(16)).join('');
}

export default hex;
