export default {
  default: 'usd',
  gb: 'gbp',
  us: 'usd',
  ca: 'usd',
  ae: 'aed',

  at: 'eur',
  be: 'eur',
  de: 'eur',
  gr: 'eur',
  ie: 'eur',
  es: 'eur',
  it: 'eur',
  cy: 'eur',
  lv: 'eur',
  lt: 'eur',
  lu: 'eur',
  mt: 'eur',
  nl: 'eur',
  pt: 'eur',
  sk: 'eur',
  sl: 'eur',
  fi: 'eur',
  fr: 'eur',
  ee: 'eur',
};
