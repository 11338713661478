import amplitude from 'amplitude-js';
import { isEmpty } from 'lodash';
import storage from '@/plugins/storage';

const EVENT_LIFE_TIME = 5;

class AmplitudePlugin {
  /**
   * Constructor.
   *
   * @param {string} apiKey
   */
  constructor(apiKey) {
    this.instance = amplitude.getInstance();

    if (apiKey) {
      this.instance.init(apiKey);
      this.enabled = true;
    } else {
      this.enabled = false;
    }
  }

  /**
   * Call amplitude event.
   *
   * @param {string} eventName
   * @param {any} eventProperties
   */
  event(eventName, eventProperties) {
    if (!this.enabled) {
      return;
    }

    this.instance.logEvent(eventName, eventProperties);
  }

  /**
   * Call amplitude event once.
   *
   * @param {string} eventName
   * @param {any} eventProperties
   * @param {string} id
   */
  eventOnce(eventName, eventProperties, id) {
    const parentKey = 'amplitude-once-events';
    const parentValue = storage.get(parentKey);
    const currentTime = +new Date();

    if (!isEmpty(parentValue)) {
      const childValue = parentValue[eventName];

      if (!isEmpty(childValue)
        && childValue?.id === id
        && (currentTime - Number(childValue?.time) < (EVENT_LIFE_TIME * 60 * 1000))
      ) {
        return;
      }

      delete parentValue[eventName];
    }

    const value = {
      ...parentValue,
      [eventName]: {
        id,
        time: currentTime,
      },
    };

    storage.set(parentKey, value);
    this.event(eventName, eventProperties);
  }

  /**
   * Install as vuejs plugin.
   *
   * @param {Vue} Vue
   */
  install(Vue) {
    Object.defineProperty(Vue.prototype, '$amplitude', {
      get: () => {
        return this;
      },
    });
  }
}

export default new AmplitudePlugin(process.env.VUE_APP_AMPLITUDE_KEY);
