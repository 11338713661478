/* eslint-disable no-underscore-dangle */

import axios from 'axios';
import hex from '@/utils/hex';
import storage from '@/plugins/storage';

import { APP_PLATFORM, APP_VERSION } from '@/config/app';
import {
  API_ADMIN_BASEURL,
  API_ADMIN_TIMEOUT,
  API_PAY_BASEURL,
  API_PAY_TIMEOUT,
} from '@/config/api';

function getSpanId() {
  return hex(16);
}

function getTraceId() {
  if (!window.__TRACE_ID__) {
    window.__TRACE_ID__ = hex(16);
  }

  return window.__TRACE_ID__;
}

function getClientId() {
  let clientId = storage.get('_client-id');

  if (clientId) {
    return clientId;
  }

  clientId = hex(16);
  storage.set('_client-id', clientId);

  return clientId;
}

function createHeaders(axiosInstance) {
  axiosInstance.interceptors.request.use((config) => {
    config.headers['X-B3-ClientId'] = getClientId();
    config.headers['X-B3-TraceId'] = getTraceId();
    config.headers['X-B3-SpanId'] = getSpanId();
    config.headers['X-App-Platform'] = APP_PLATFORM;
    config.headers['X-App-Version'] = APP_VERSION;

    return config;
  });
}

const admin = axios.create({
  baseURL: API_ADMIN_BASEURL,
  timeout: API_ADMIN_TIMEOUT,
  method: 'get',
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const pay = axios.create({
  baseURL: API_PAY_BASEURL,
  timeout: API_PAY_TIMEOUT,
  method: 'get',
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

createHeaders(admin);
createHeaders(pay);

export default {
  admin,
  pay,
};
