<template>
  <div class="location-background">
    <img :src="backgroundLink" class="location-background__image">
  </div>
</template>

<script>
export default {
  name: 'LocationBackground',

  props: {
    backgroundLink: {
      type: String,
      required: true,
    },
  },
};
</script>
