<template>
  <div class="ui-locale" :class="{ opened }">
    <div class="ui-locale__selection" @click="opened = !opened">
      <div class="ui-locale__flag" :class="locale" />
      <span class="ui-locale__arrow">
        {{ opened ? '▲' : '▼' }}
      </span>
    </div>
    <div class="ui-locale__dropdown">
      <div
        v-for="val in availableLocales"
        :key="val"
        class="ui-locale__dropdown-item"
        :class="{ selected: locale === val }"
        @click="select(val)"
      >
        <div class="ui-locale__flag" :class="val" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {

  data: () => ({
    opened: false,
  }),

  computed: {
    ...mapState('i18n', [
      'locale',
      'locales',
    ]),
    availableCountries() {
      if (this.$apiSwitch) {
        return this.$apiSwitch.selected?.country || [];
      }

      return [];
    },
    availableLocales() {
      if (this.availableCountries.length) {
        return this.locales.filter((value) => {
          if (value === 'en') {
            value = 'gb';
          }

          return this.availableCountries.includes(value);
        });
      }

      return this.locales;
    },
  },

  watch: {
    availableLocales: {
      immediate: true,
      handler(locales) {
        if (!locales.includes(this.locale)) {
          this.setLocale(locales[0]);
        }
      },
    },
  },

  methods: {
    ...mapMutations('i18n', [
      'setLocale',
    ]),
    select(locale) {
      const fromLocale = this.locale;

      this.setLocale(locale);
      this.opened = false;

      this.$emit('update:locale', {
        from: fromLocale,
        to: locale,
      });
    },
  },
};
</script>

<style lang="scss">
.ui-locale {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  position: relative;
  padding: 7px;
  cursor: pointer;

  &__selection {
    display: flex;
    align-items: center;
  }

  &__arrow {
    margin-left: 5px;
    transform: scaleY(.5);
    display: inline-block;
    color: #666;
  }

  &__dropdown {
    display: none;
    flex-direction: column;
    z-index: 1;
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    left: 1px;
    top: 33px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.32);

    &-item {
      padding: 4px 6px;

      &.selected {
        background: #2992ff;
      }
    }
  }

  &.opened &__dropdown {
    display: flex;
  }
}

.ui-locale__flag {
  width: 20px;
  background-size: 5630px 15px;
}
.ui-locale__flag.be {
  width: 18px;
}
.ui-locale__flag.ch {
  width: 15px;
}
.ui-locale__flag.mc {
  width: 19px;
}
.ui-locale__flag.ne {
  width: 18px;
}
.ui-locale__flag.np {
  width: 13px;
}
.ui-locale__flag.va {
  width: 15px;
}
.ui-locale__flag.ac {
  height: 10px;
  background-position: 0px 0px;
}
.ui-locale__flag.ad {
  height: 14px;
  background-position: -22px 0px;
}
.ui-locale__flag.ar,
.ui-locale__flag.ae {
  height: 10px;
  background-position: -44px 0px;
}
.ui-locale__flag.af {
  height: 14px;
  background-position: -66px 0px;
}
.ui-locale__flag.ag {
  height: 14px;
  background-position: -88px 0px;
}
.ui-locale__flag.ai {
  height: 10px;
  background-position: -110px 0px;
}
.ui-locale__flag.al {
  height: 15px;
  background-position: -132px 0px;
}
.ui-locale__flag.am {
  height: 10px;
  background-position: -154px 0px;
}
.ui-locale__flag.ao {
  height: 14px;
  background-position: -176px 0px;
}
.ui-locale__flag.aq {
  height: 14px;
  background-position: -198px 0px;
}
.ui-locale__flag.ar-iso {
  height: 13px;
  background-position: -220px 0px;
}
.ui-locale__flag.as {
  height: 10px;
  background-position: -242px 0px;
}
.ui-locale__flag.at {
  height: 14px;
  background-position: -264px 0px;
}
.ui-locale__flag.au {
  height: 10px;
  background-position: -286px 0px;
}
.ui-locale__flag.aw {
  height: 14px;
  background-position: -308px 0px;
}
.ui-locale__flag.ax {
  height: 13px;
  background-position: -330px 0px;
}
.ui-locale__flag.az {
  height: 10px;
  background-position: -352px 0px;
}
.ui-locale__flag.ba {
  height: 10px;
  background-position: -374px 0px;
}
.ui-locale__flag.bb {
  height: 14px;
  background-position: -396px 0px;
}
.ui-locale__flag.bd {
  height: 12px;
  background-position: -418px 0px;
}
.ui-locale__flag.be {
  height: 15px;
  background-position: -440px 0px;
}
.ui-locale__flag.bf {
  height: 14px;
  background-position: -460px 0px;
}
.ui-locale__flag.bg {
  height: 12px;
  background-position: -482px 0px;
}
.ui-locale__flag.bh {
  height: 12px;
  background-position: -504px 0px;
}
.ui-locale__flag.bi {
  height: 12px;
  background-position: -526px 0px;
}
.ui-locale__flag.bj {
  height: 14px;
  background-position: -548px 0px;
}
.ui-locale__flag.bl {
  height: 14px;
  background-position: -570px 0px;
}
.ui-locale__flag.bm {
  height: 10px;
  background-position: -592px 0px;
}
.ui-locale__flag.bn {
  height: 10px;
  background-position: -614px 0px;
}
.ui-locale__flag.bo {
  height: 14px;
  background-position: -636px 0px;
}
.ui-locale__flag.bq {
  height: 14px;
  background-position: -658px 0px;
}
.ui-locale__flag.br {
  height: 14px;
  background-position: -680px 0px;
}
.ui-locale__flag.bs {
  height: 10px;
  background-position: -702px 0px;
}
.ui-locale__flag.bt {
  height: 14px;
  background-position: -724px 0px;
}
.ui-locale__flag.bv {
  height: 15px;
  background-position: -746px 0px;
}
.ui-locale__flag.bw {
  height: 14px;
  background-position: -768px 0px;
}
.ui-locale__flag.by {
  height: 10px;
  background-position: -790px 0px;
}
.ui-locale__flag.bz {
  height: 14px;
  background-position: -812px 0px;
}
.ui-locale__flag.ca {
  height: 10px;
  background-position: -834px 0px;
}
.ui-locale__flag.cc {
  height: 10px;
  background-position: -856px 0px;
}
.ui-locale__flag.cd {
  height: 15px;
  background-position: -878px 0px;
}
.ui-locale__flag.cf {
  height: 14px;
  background-position: -900px 0px;
}
.ui-locale__flag.cg {
  height: 14px;
  background-position: -922px 0px;
}
.ui-locale__flag.ch {
  height: 15px;
  background-position: -944px 0px;
}
.ui-locale__flag.ci {
  height: 14px;
  background-position: -961px 0px;
}
.ui-locale__flag.ck {
  height: 10px;
  background-position: -983px 0px;
}
.ui-locale__flag.cl {
  height: 14px;
  background-position: -1005px 0px;
}
.ui-locale__flag.cm {
  height: 14px;
  background-position: -1027px 0px;
}
.ui-locale__flag.cn {
  height: 14px;
  background-position: -1049px 0px;
}
.ui-locale__flag.co {
  height: 14px;
  background-position: -1071px 0px;
}
.ui-locale__flag.cp {
  height: 14px;
  background-position: -1093px 0px;
}
.ui-locale__flag.cr {
  height: 12px;
  background-position: -1115px 0px;
}
.ui-locale__flag.cu {
  height: 10px;
  background-position: -1137px 0px;
}
.ui-locale__flag.cv {
  height: 12px;
  background-position: -1159px 0px;
}
.ui-locale__flag.cw {
  height: 14px;
  background-position: -1181px 0px;
}
.ui-locale__flag.cx {
  height: 10px;
  background-position: -1203px 0px;
}
.ui-locale__flag.cy {
  height: 14px;
  background-position: -1225px 0px;
}
.ui-locale__flag.cz {
  height: 14px;
  background-position: -1247px 0px;
}
.ui-locale__flag.de {
  height: 12px;
  background-position: -1269px 0px;
}
.ui-locale__flag.dg {
  height: 10px;
  background-position: -1291px 0px;
}
.ui-locale__flag.dj {
  height: 14px;
  background-position: -1313px 0px;
}
.ui-locale__flag.dk {
  height: 15px;
  background-position: -1335px 0px;
}
.ui-locale__flag.dm {
  height: 10px;
  background-position: -1357px 0px;
}
.ui-locale__flag.do {
  height: 13px;
  background-position: -1379px 0px;
}
.ui-locale__flag.dz {
  height: 14px;
  background-position: -1401px 0px;
}
.ui-locale__flag.ea {
  height: 14px;
  background-position: -1423px 0px;
}
.ui-locale__flag.ec {
  height: 14px;
  background-position: -1445px 0px;
}
.ui-locale__flag.ee {
  height: 13px;
  background-position: -1467px 0px;
}
.ui-locale__flag.eg {
  height: 14px;
  background-position: -1489px 0px;
}
.ui-locale__flag.eh {
  height: 10px;
  background-position: -1511px 0px;
}
.ui-locale__flag.er {
  height: 10px;
  background-position: -1533px 0px;
}
.ui-locale__flag.es {
  height: 14px;
  background-position: -1555px 0px;
}
.ui-locale__flag.et {
  height: 10px;
  background-position: -1577px 0px;
}
.ui-locale__flag.eu {
  height: 14px;
  background-position: -1599px 0px;
}
.ui-locale__flag.fi {
  height: 12px;
  background-position: -1621px 0px;
}
.ui-locale__flag.fj {
  height: 10px;
  background-position: -1643px 0px;
}
.ui-locale__flag.fk {
  height: 10px;
  background-position: -1665px 0px;
}
.ui-locale__flag.fm {
  height: 11px;
  background-position: -1687px 0px;
}
.ui-locale__flag.fo {
  height: 15px;
  background-position: -1709px 0px;
}
.ui-locale__flag.fr {
  height: 14px;
  background-position: -1731px 0px;
}
.ui-locale__flag.ga {
  height: 15px;
  background-position: -1753px 0px;
}
.ui-locale__flag.en,
.ui-locale__flag.gb {
  height: 10px;
  background-position: -1775px 0px;
}
.ui-locale__flag.gd {
  height: 12px;
  background-position: -1797px 0px;
}
.ui-locale__flag.ge {
  height: 14px;
  background-position: -1819px 0px;
}
.ui-locale__flag.gf {
  height: 14px;
  background-position: -1841px 0px;
}
.ui-locale__flag.gg {
  height: 14px;
  background-position: -1863px 0px;
}
.ui-locale__flag.gh {
  height: 14px;
  background-position: -1885px 0px;
}
.ui-locale__flag.gi {
  height: 10px;
  background-position: -1907px 0px;
}
.ui-locale__flag.gl {
  height: 14px;
  background-position: -1929px 0px;
}
.ui-locale__flag.gm {
  height: 14px;
  background-position: -1951px 0px;
}
.ui-locale__flag.gn {
  height: 14px;
  background-position: -1973px 0px;
}
.ui-locale__flag.gp {
  height: 14px;
  background-position: -1995px 0px;
}
.ui-locale__flag.gq {
  height: 14px;
  background-position: -2017px 0px;
}
.ui-locale__flag.gr {
  height: 14px;
  background-position: -2039px 0px;
}
.ui-locale__flag.gs {
  height: 10px;
  background-position: -2061px 0px;
}
.ui-locale__flag.gt {
  height: 13px;
  background-position: -2083px 0px;
}
.ui-locale__flag.gu {
  height: 11px;
  background-position: -2105px 0px;
}
.ui-locale__flag.gw {
  height: 10px;
  background-position: -2127px 0px;
}
.ui-locale__flag.gy {
  height: 12px;
  background-position: -2149px 0px;
}
.ui-locale__flag.hk {
  height: 14px;
  background-position: -2171px 0px;
}
.ui-locale__flag.hm {
  height: 10px;
  background-position: -2193px 0px;
}
.ui-locale__flag.hn {
  height: 10px;
  background-position: -2215px 0px;
}
.ui-locale__flag.hr {
  height: 10px;
  background-position: -2237px 0px;
}
.ui-locale__flag.ht {
  height: 12px;
  background-position: -2259px 0px;
}
.ui-locale__flag.hu {
  height: 10px;
  background-position: -2281px 0px;
}
.ui-locale__flag.ic {
  height: 14px;
  background-position: -2303px 0px;
}
.ui-locale__flag.id {
  height: 14px;
  background-position: -2325px 0px;
}
.ui-locale__flag.ie {
  height: 10px;
  background-position: -2347px 0px;
}
.ui-locale__flag.il {
  height: 15px;
  background-position: -2369px 0px;
}
.ui-locale__flag.im {
  height: 10px;
  background-position: -2391px 0px;
}
.ui-locale__flag.in {
  height: 14px;
  background-position: -2413px 0px;
}
.ui-locale__flag.io {
  height: 10px;
  background-position: -2435px 0px;
}
.ui-locale__flag.iq {
  height: 14px;
  background-position: -2457px 0px;
}
.ui-locale__flag.ir {
  height: 12px;
  background-position: -2479px 0px;
}
.ui-locale__flag.is {
  height: 15px;
  background-position: -2501px 0px;
}
.ui-locale__flag.it {
  height: 14px;
  background-position: -2523px 0px;
}
.ui-locale__flag.je {
  height: 12px;
  background-position: -2545px 0px;
}
.ui-locale__flag.jm {
  height: 10px;
  background-position: -2567px 0px;
}
.ui-locale__flag.jo {
  height: 10px;
  background-position: -2589px 0px;
}
.ui-locale__flag.jp {
  height: 14px;
  background-position: -2611px 0px;
}
.ui-locale__flag.ke {
  height: 14px;
  background-position: -2633px 0px;
}
.ui-locale__flag.kg {
  height: 12px;
  background-position: -2655px 0px;
}
.ui-locale__flag.kh {
  height: 13px;
  background-position: -2677px 0px;
}
.ui-locale__flag.ki {
  height: 10px;
  background-position: -2699px 0px;
}
.ui-locale__flag.km {
  height: 12px;
  background-position: -2721px 0px;
}
.ui-locale__flag.kn {
  height: 14px;
  background-position: -2743px 0px;
}
.ui-locale__flag.kp {
  height: 10px;
  background-position: -2765px 0px;
}
.ui-locale__flag.kr {
  height: 14px;
  background-position: -2787px 0px;
}
.ui-locale__flag.kw {
  height: 10px;
  background-position: -2809px 0px;
}
.ui-locale__flag.ky {
  height: 10px;
  background-position: -2831px 0px;
}
.ui-locale__flag.kz {
  height: 10px;
  background-position: -2853px 0px;
}
.ui-locale__flag.la {
  height: 14px;
  background-position: -2875px 0px;
}
.ui-locale__flag.lb {
  height: 14px;
  background-position: -2897px 0px;
}
.ui-locale__flag.lc {
  height: 10px;
  background-position: -2919px 0px;
}
.ui-locale__flag.li {
  height: 12px;
  background-position: -2941px 0px;
}
.ui-locale__flag.lk {
  height: 10px;
  background-position: -2963px 0px;
}
.ui-locale__flag.lr {
  height: 11px;
  background-position: -2985px 0px;
}
.ui-locale__flag.ls {
  height: 14px;
  background-position: -3007px 0px;
}
.ui-locale__flag.lt {
  height: 12px;
  background-position: -3029px 0px;
}
.ui-locale__flag.lu {
  height: 12px;
  background-position: -3051px 0px;
}
.ui-locale__flag.lv {
  height: 10px;
  background-position: -3073px 0px;
}
.ui-locale__flag.ly {
  height: 10px;
  background-position: -3095px 0px;
}
.ui-locale__flag.ma {
  height: 14px;
  background-position: -3117px 0px;
}
.ui-locale__flag.mc {
  height: 15px;
  background-position: -3139px 0px;
}
.ui-locale__flag.md {
  height: 10px;
  background-position: -3160px 0px;
}
.ui-locale__flag.me {
  height: 10px;
  background-position: -3182px 0px;
}
.ui-locale__flag.mf {
  height: 14px;
  background-position: -3204px 0px;
}
.ui-locale__flag.mg {
  height: 14px;
  background-position: -3226px 0px;
}
.ui-locale__flag.mh {
  height: 11px;
  background-position: -3248px 0px;
}
.ui-locale__flag.mk {
  height: 10px;
  background-position: -3270px 0px;
}
.ui-locale__flag.ml {
  height: 14px;
  background-position: -3292px 0px;
}
.ui-locale__flag.mm {
  height: 14px;
  background-position: -3314px 0px;
}
.ui-locale__flag.mn {
  height: 10px;
  background-position: -3336px 0px;
}
.ui-locale__flag.mo {
  height: 14px;
  background-position: -3358px 0px;
}
.ui-locale__flag.mp {
  height: 10px;
  background-position: -3380px 0px;
}
.ui-locale__flag.mq {
  height: 14px;
  background-position: -3402px 0px;
}
.ui-locale__flag.mr {
  height: 14px;
  background-position: -3424px 0px;
}
.ui-locale__flag.ms {
  height: 10px;
  background-position: -3446px 0px;
}
.ui-locale__flag.mt {
  height: 14px;
  background-position: -3468px 0px;
}
.ui-locale__flag.mu {
  height: 14px;
  background-position: -3490px 0px;
}
.ui-locale__flag.mv {
  height: 14px;
  background-position: -3512px 0px;
}
.ui-locale__flag.mw {
  height: 14px;
  background-position: -3534px 0px;
}
.ui-locale__flag.mx {
  height: 12px;
  background-position: -3556px 0px;
}
.ui-locale__flag.my {
  height: 10px;
  background-position: -3578px 0px;
}
.ui-locale__flag.mz {
  height: 14px;
  background-position: -3600px 0px;
}
.ui-locale__flag.na {
  height: 14px;
  background-position: -3622px 0px;
}
.ui-locale__flag.nc {
  height: 10px;
  background-position: -3644px 0px;
}
.ui-locale__flag.ne {
  height: 15px;
  background-position: -3666px 0px;
}
.ui-locale__flag.nf {
  height: 10px;
  background-position: -3686px 0px;
}
.ui-locale__flag.ng {
  height: 10px;
  background-position: -3708px 0px;
}
.ui-locale__flag.ni {
  height: 12px;
  background-position: -3730px 0px;
}
.ui-locale__flag.nl {
  height: 14px;
  background-position: -3752px 0px;
}
.ui-locale__flag.no {
  height: 15px;
  background-position: -3774px 0px;
}
.ui-locale__flag.np {
  height: 15px;
  background-position: -3796px 0px;
}
.ui-locale__flag.nr {
  height: 10px;
  background-position: -3811px 0px;
}
.ui-locale__flag.nu {
  height: 10px;
  background-position: -3833px 0px;
}
.ui-locale__flag.nz {
  height: 10px;
  background-position: -3855px 0px;
}
.ui-locale__flag.om {
  height: 10px;
  background-position: -3877px 0px;
}
.ui-locale__flag.pa {
  height: 14px;
  background-position: -3899px 0px;
}
.ui-locale__flag.pe {
  height: 14px;
  background-position: -3921px 0px;
}
.ui-locale__flag.pf {
  height: 14px;
  background-position: -3943px 0px;
}
.ui-locale__flag.pg {
  height: 15px;
  background-position: -3965px 0px;
}
.ui-locale__flag.ph {
  height: 10px;
  background-position: -3987px 0px;
}
.ui-locale__flag.pk {
  height: 14px;
  background-position: -4009px 0px;
}
.ui-locale__flag.pl {
  height: 13px;
  background-position: -4031px 0px;
}
.ui-locale__flag.pm {
  height: 14px;
  background-position: -4053px 0px;
}
.ui-locale__flag.pn {
  height: 10px;
  background-position: -4075px 0px;
}
.ui-locale__flag.pr {
  height: 14px;
  background-position: -4097px 0px;
}
.ui-locale__flag.ps {
  height: 10px;
  background-position: -4119px 0px;
}
.ui-locale__flag.pt {
  height: 14px;
  background-position: -4141px 0px;
}
.ui-locale__flag.pw {
  height: 13px;
  background-position: -4163px 0px;
}
.ui-locale__flag.py {
  height: 11px;
  background-position: -4185px 0px;
}
.ui-locale__flag.qa {
  height: 8px;
  background-position: -4207px 0px;
}
.ui-locale__flag.re {
  height: 14px;
  background-position: -4229px 0px;
}
.ui-locale__flag.ro {
  height: 14px;
  background-position: -4251px 0px;
}
.ui-locale__flag.rs {
  height: 14px;
  background-position: -4273px 0px;
}
.ui-locale__flag.ru {
  height: 14px;
  background-position: -4295px 0px;
}
.ui-locale__flag.rw {
  height: 14px;
  background-position: -4317px 0px;
}
.ui-locale__flag.sa {
  height: 14px;
  background-position: -4339px 0px;
}
.ui-locale__flag.sb {
  height: 10px;
  background-position: -4361px 0px;
}
.ui-locale__flag.sc {
  height: 10px;
  background-position: -4383px 0px;
}
.ui-locale__flag.sd {
  height: 10px;
  background-position: -4405px 0px;
}
.ui-locale__flag.se {
  height: 13px;
  background-position: -4427px 0px;
}
.ui-locale__flag.sg {
  height: 14px;
  background-position: -4449px 0px;
}
.ui-locale__flag.sh {
  height: 10px;
  background-position: -4471px 0px;
}
.ui-locale__flag.si {
  height: 10px;
  background-position: -4493px 0px;
}
.ui-locale__flag.sj {
  height: 15px;
  background-position: -4515px 0px;
}
.ui-locale__flag.sk {
  height: 14px;
  background-position: -4537px 0px;
}
.ui-locale__flag.sl {
  height: 14px;
  background-position: -4559px 0px;
}
.ui-locale__flag.sm {
  height: 15px;
  background-position: -4581px 0px;
}
.ui-locale__flag.sn {
  height: 14px;
  background-position: -4603px 0px;
}
.ui-locale__flag.so {
  height: 14px;
  background-position: -4625px 0px;
}
.ui-locale__flag.sr {
  height: 14px;
  background-position: -4647px 0px;
}
.ui-locale__flag.ss {
  height: 10px;
  background-position: -4669px 0px;
}
.ui-locale__flag.st {
  height: 10px;
  background-position: -4691px 0px;
}
.ui-locale__flag.sv {
  height: 12px;
  background-position: -4713px 0px;
}
.ui-locale__flag.sx {
  height: 14px;
  background-position: -4735px 0px;
}
.ui-locale__flag.sy {
  height: 14px;
  background-position: -4757px 0px;
}
.ui-locale__flag.sz {
  height: 14px;
  background-position: -4779px 0px;
}
.ui-locale__flag.ta {
  height: 10px;
  background-position: -4801px 0px;
}
.ui-locale__flag.tc {
  height: 10px;
  background-position: -4823px 0px;
}
.ui-locale__flag.td {
  height: 14px;
  background-position: -4845px 0px;
}
.ui-locale__flag.tf {
  height: 14px;
  background-position: -4867px 0px;
}
.ui-locale__flag.tg {
  height: 13px;
  background-position: -4889px 0px;
}
.ui-locale__flag.th {
  height: 14px;
  background-position: -4911px 0px;
}
.ui-locale__flag.tj {
  height: 10px;
  background-position: -4933px 0px;
}
.ui-locale__flag.tk {
  height: 10px;
  background-position: -4955px 0px;
}
.ui-locale__flag.tl {
  height: 10px;
  background-position: -4977px 0px;
}
.ui-locale__flag.tm {
  height: 14px;
  background-position: -4999px 0px;
}
.ui-locale__flag.tn {
  height: 14px;
  background-position: -5021px 0px;
}
.ui-locale__flag.to {
  height: 10px;
  background-position: -5043px 0px;
}
.ui-locale__flag.tr {
  height: 14px;
  background-position: -5065px 0px;
}
.ui-locale__flag.tt {
  height: 12px;
  background-position: -5087px 0px;
}
.ui-locale__flag.tv {
  height: 10px;
  background-position: -5109px 0px;
}
.ui-locale__flag.tw {
  height: 14px;
  background-position: -5131px 0px;
}
.ui-locale__flag.tz {
  height: 14px;
  background-position: -5153px 0px;
}
.ui-locale__flag.ua {
  height: 14px;
  background-position: -5175px 0px;
}
.ui-locale__flag.ug {
  height: 14px;
  background-position: -5197px 0px;
}
.ui-locale__flag.um {
  height: 11px;
  background-position: -5219px 0px;
}
.ui-locale__flag.us {
  height: 11px;
  background-position: -5241px 0px;
}
.ui-locale__flag.uy {
  height: 14px;
  background-position: -5263px 0px;
}
.ui-locale__flag.uz {
  height: 10px;
  background-position: -5285px 0px;
}
.ui-locale__flag.va {
  height: 15px;
  background-position: -5307px 0px;
}
.ui-locale__flag.vc {
  height: 14px;
  background-position: -5324px 0px;
}
.ui-locale__flag.ve {
  height: 14px;
  background-position: -5346px 0px;
}
.ui-locale__flag.vg {
  height: 10px;
  background-position: -5368px 0px;
}
.ui-locale__flag.vi {
  height: 14px;
  background-position: -5390px 0px;
}
.ui-locale__flag.vn {
  height: 14px;
  background-position: -5412px 0px;
}
.ui-locale__flag.vu {
  height: 12px;
  background-position: -5434px 0px;
}
.ui-locale__flag.wf {
  height: 14px;
  background-position: -5456px 0px;
}
.ui-locale__flag.ws {
  height: 10px;
  background-position: -5478px 0px;
}
.ui-locale__flag.xk {
  height: 15px;
  background-position: -5500px 0px;
}
.ui-locale__flag.ye {
  height: 14px;
  background-position: -5522px 0px;
}
.ui-locale__flag.yt {
  height: 14px;
  background-position: -5544px 0px;
}
.ui-locale__flag.za {
  height: 14px;
  background-position: -5566px 0px;
}
.ui-locale__flag.zm {
  height: 14px;
  background-position: -5588px 0px;
}
.ui-locale__flag.zw {
  height: 10px;
  background-position: -5610px 0px;
}
.ui-locale__flag {
  width: 20px;
  height: 15px !important;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url(~@/assets/flags.png);
  background-repeat: no-repeat;
  background-color: #dbdbdb;
  background-position: 20px 0;
}
.ui-locale__flag.np {
  background-color: transparent;
}
</style>
