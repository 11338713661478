export default function (fn) {
  return {
    data() {
      return {
        ...fn.call(this),
      };
    },

    methods: {
      reset() {
        Object.entries(fn.call(this)).forEach(([key, value]) => {
          this[key] = value;
        });
      },
    },
  };
}
