import axios from 'axios';
import instance from './instance';

function createCancellable(axiosInstance) {
  return (config) => {
    const cancelSource = axios.CancelToken.source();

    const req = axiosInstance({
      cancelToken: cancelSource.token,
      ...config,
    }).catch((error) => {
      if (axios.isCancel(error)) {
        error.isCancelled = true;
      }

      throw error;
    });

    req.cancel = () => {
      cancelSource.cancel();
    };

    return req;
  };
}

const admin = createCancellable(instance.admin);
const pay = createCancellable(instance.pay);

export default {
  admin,
  pay,
};
